/* import Roboto font*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

body{
  font-family: roboto , sans-serif;
  font-size: 16px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mandatory  .label-style:after {
  content: " *";
  font-size: 18px;
  font-weight: bolder;
  line-height:0;
}

/* nav bar customization */

.navbar {
  position: sticky;
  top: 0;
}

.navbar-nav a {
  font-size: 16px;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-light 
.navbar-nav .nav-link:hover {
  color:#fff;
  text-decoration: underline;
}

span.navbar-brand img {
  margin-left: 10px;
  width: 60px;
  filter: brightness(100000);
}
header .topinfo {
  color: #fff;
}

nav.navbar.navbar-light.bg-secondary.sticky-top.navbar.navbar-expand.navbar-light {
  flex-direction: column;
  padding-bottom: 0;
  background: #03316d linear-gradient(99deg, #063579 0%, #074EB4 100%) !important;
}
.navbar-expand .navbar-nav .nav-link{
  color:#fff;
}
.navbar-nav {
  flex: 1;
}

.navbar-nav-holder {
  flex: 1;
  display: flex;
  flex-direction: column;
}

span.profileicon {
  background: #fff;
  border-radius: 50%;
  line-height: 35px;
  width: 35px;
  height: 35px;
  color: #03316d !important;
  margin-left: 10px;
  text-align: center;
}
.toplinks {
  font-size: 14px;
  text-align: right;
  line-height: 1;
  margin-bottom: 22px;
  padding-right: 10px;
  color: #fff;
  display: flex;
  float: right;
  justify-content: end;
  align-items: center;
}
.toplinks a{
  color:#fff;
  text-decoration: none !important;
}
.toplinks a:hover{
  color:#fff;
  text-decoration: underline;
}
.topinfo {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
  border-top: 1px solid #aaa;
  padding-top: 5px;
  margin-top: 10px;
}
button.primary.btn.button {
  background: #03316d linear-gradient(99deg, #063579 0%, #074EB4 100%) !important;
  color: #fff;
  border: 1px solid #03316d;
  font-family: roboto;
    font-weight: 700;
}
button.primary.btn.button:hover {
  background: #398fda;
  color: #fff;
  border: 1px solid #398fda;
}

button.outline.btn.button {
  border: 1px solid #03316d;
  color: #03316d;
  background: transparent;
  font-weight: 600;
}
button.outline.btn.button:hover {
  border: 1px solid #398fda;
  color: #398fda;
}
header a.ml-auto.nav-link svg {
  font-size: 20px;
  position: relative;
  top: -2px;
}
.draftbar .btn {
  margin: 10px 0px;
  padding: 3px 13px;
  font-size: 14px;
  min-width:120px;
}
.draftbar .btn + .btn {margin-left: 10px;}

.draftbar > .container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: end;
  width: 100%;
}
.draftbar {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  border-bottom: 1px solid #aaa;
}
.draftbar > .container > span {
  margin-right: auto;
  color: #03316d;
  font-size: 14px;
}

.hidemobile .topinfo {
  position: absolute;
  left: 0;
  right: 0;
  max-width: inherit;
  padding: 10px;
  top: 0;
  background: #398fda;
  border-radius:  5px 5px;
  border: 0 none transparent;
  box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
}
img.position-icon {
  /* filter: brightness(100) !important; */
  max-height: 22px;
  /* filter: url("filters.svg#filter-id"); */
  filter: brightness(10000);
}
/*responsive header settings*/
@media(max-width:767px){
  .topinfo {
    display: block;
    padding: 0 10px;
    text-align: center;
}

.draftbar > .container{
    display: block;
    text-align: center;
}

.draftbar > .container > span {
  margin: 0px auto 0;
  display: block;
}
.toplinks {
  line-height: 1.3;
  margin-bottom: 10px;
}
.topinfo > span {
  margin: 0 5px;
  display: inline-block;
}
.draftbar .btn {
  margin: 5px 0;
}

}
@media (min-width:768px){
  img.position-icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
}

.topinfo {
    padding-left: 70px !important;
    padding-right: 20px !important;
}

.topinfo:before {
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    width: 50px;
    background: transparent;
    display: block;
    height: 41px;
    position: absolute;
    border-radius: 5px 0px 0 5px;
    border-right: 1px solid #fff;
}
}

/*footer settings*/

footer {
  background: #ddd;
  padding: 5px;
  font-size: 14px;
}

.draftbar {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 10px;
  z-index: 100;
  border-top: 1px solid #aaa;
}
div#moreinfop {
  min-height: 28px;
}

/*min height of body content*/
.pageheight {
  min-height: calc(100vh - 193px);
  padding-top: 60px;
}
.pageheight-orderPage{
  min-height: calc(100vh - 120px);
  padding-top: 60px;
}
.patients-list-pageheight{
  min-height: calc(100vh - 120px);
  padding-top: 20px;
}
.results-inner-pageheight{
  min-height: calc(100vh - 120px);
  padding-top: 60px;
}

@media (max-width:767px){
  .pageheight {
    min-height: calc(100vh - 243px);
    padding-top: 30px;
}
}
#root{
  background-color: #f4fbff !important;
}

/*settings for accordians*/
.accordion-button {
  background-color: rgb(243, 234, 234) ;
  font-size: 16px !important;   
  margin-bottom: 5px;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #212529;
}




button.accordion-button {
  background: #03316d linear-gradient(99deg, #063579 0%, #074EB4 100%) !important;
  color: #fff !important;
  font-family: roboto;
    font-weight: 700;
    margin: 0;
}
.accordion {
  margin: 0 0 10px;
}
.accordion .accordion 
button.accordion-button {
  background:#e7e7e7 !important;
  color:#03316d !important;
  border-radius: 0 !important;
  margin: 0px -20px;
  width: calc(100% + 40px)!important;
  border-top: 1px solid #ddd;
  box-shadow: none;
}

.accordion .accordion h2.accordion-header {
  border: 0 none transparent 
!important;
}

.accordion-item {
  box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
  border: 0 none transparent !important;
}
.accordion-item  .accordion-item  {
  box-shadow: none;
}

.accordion .accordion .accordion-item {
  border: 0 none transparent;
}

.accordion-body .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.accordion-body .accordion-body .label-style.container {
  padding-left: 0;
  padding-right: 0;
}

.accordion-button:not(.collapsed):after {
  content: "-";
  color: #fff;
  font-weight: 900;
  font-size: 30px;
    line-height: 0.5;
  background-image: none !important;
  text-align: center;
}

.accordion-button.collapsed:after {
  content: "+";
  color: #fff;
  font-weight: 900;
  font-size: 30px;
    line-height: 0.5;
  background-image: none;
  text-align: center;
}

.accordion .accordion .accordion-button.collapsed:after {
  color: #03316d !important;
background:none !important;
}

.accordion .accordion .accordion-button:not(.collapsed):after {
  color: #03316d !important;
  background:none !important;
}
.complete > .accordion-item > h2 > button.accordion-button:before {
  content: "\2713 ";
  font-weight: 900;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  background: #85cb50;
  margin-right: 10px;
  color: #03316d;
}

button.accordion-button {
  box-shadow: 0 5px 10px rgb(0 0 0 / 50%) !important;
}
.accordion .accordion button.accordion-button {
  box-shadow: 0 0 0 rgb(0 0 0 / 0%) !important;
}




/*form styling*/
.input-style{
  font-size: 14px !important;
  font-family: Roboto, sans-serif;
  background-color: rgb(255, 255, 255) !important;
  margin-bottom: 10px;
}

.label-style{
  font-family: Roboto, sans-serif;
  font-size: 14px !important;   

}

.radio-input-style{
  font-size: 14px !important;
  font-family: Roboto, sans-serif;   
}


textarea.input-style.form-control {
 height: 110px !important;
  min-height: 110px !important;
 max-height: 110px !important;

}

.formspacing .input-style {
  margin-bottom:30px
}

.label-style.parentcontainer.container .col-md-4 ,
.label-style.parentcontainer.container .col-md-12 {
    margin-bottom: 10px;
}
input.form-check-input {
  margin-top: 4px !important;
}


/*signup form*/
form.grayform {
  background: #eee;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

form.grayform img {
  margin: 0 auto 31px;
  display: block;
}




/*responsive nav styling*/
nav.navbar.navbar-light.sticky-top.showmobile.navbar.navbar-expand-lg.navbar-light{
  display: none !important;
}
header {
  position: sticky;
  top: 0;
  background: #03316d linear-gradient(99deg, #063579 0%, #074EB4 100%) !important;
  z-index: 1050;
  padding-bottom: 10px;
  box-shadow: 0 10px 10px rgb(0 0 0 / 50%);
}
.showmobile{display: none !important;}
@media (max-width:767px) {
.hidemobile{display: none;}
  nav.navbar.navbar-light.sticky-top.showmobile.navbar.navbar-expand-lg.navbar-light {
    display: block !important;
  }
  .showmobile{display: block !important;}
  .topinfo {display: none;}
  .showmobile .topinfo {
    display: block;
    border: 0 none transparent;
    padding: 0;
    margin: 0;
}

html body .navbar-expand .navbar-nav {
  width: 100%;
  flex-direction: column;
  background: #03316d linear-gradient(99deg, #063579 0%, #074EB4 100%) !important;
  border-top: 1px solid #ddd;
}
nav.navbar.navbar-light.bg-secondary.sticky-top.navbar.navbar-expand.navbar-light {
  background: #03316d linear-gradient(99deg, #063579 0%, #074EB4 100%) !important;
}
span.navbar-brand img {
  width: 45px;
}

.navbar-light .navbar-nav .nav-link {
  padding: 10px 10px;
  display: block;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.navbar-expand .navbar-nav a {
  padding: 10px;
}
.grayboxcenter {
  background: #398fda;
    padding: 10px;
    text-align: center;
    border-bottom: 0px solid #ddd;
    font-size: 14px;
    width: 100%;
}
.mobilemenumaker{
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
}
.navbar-light .navbar-toggler {
  margin-right: 10px !important;
}
nav.navbar.navbar-light.sticky-top.showmobile.navbar.navbar-expand-lg.navbar-light{
  padding-bottom: 0px;
  background-color: #03316d;
}
nav.navbar.navbar-light.bg-secondary.sticky-top.navbar.navbar-expand.navbar-light {
  display: none;
}
.draftbar {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  padding: 10px;
  z-index: 100;
  border-top: 1px solid #aaa;
}
nav.navbar.navbar-light.sticky-top.showmobile .container {
  width: auto;
  max-width: none;
}
header{
  padding-bottom: 0px;
  border-bottom: 0 none transparent;
  background: transparent;
}
}


/*setting for popup*/
body.modal-open nav.navbar.navbar-light.sticky-top.showmobile.navbar.navbar-expand-lg.navbar-dark {
  padding-right: 0 !important;
}

header.hidemobile .container + .container {
  position: relative;
}
header.hidemobile {
  padding-bottom: 0;
}

header.hidemobile nav.navbar.navbar-light.bg-secondary.sticky-top.navbar.navbar-expand.navbar-light {
  padding-bottom:10px
}



/*patient list screen */
.partition {
  background: #fff;
  padding: 1em 1.5em;
  margin: 0 auto 30px;
  border-radius: 0.375rem;
  box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
}
html body .table-hover tbody tr:hover {background: #fefefe;
/* cursor: pointer; */
}
html body .table-hover tbody tr td , html body .table-hover thead tr th {
  font-family: 'Roboto';
  font-size: 14px !important;
}

.widecolumns > div > div {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 14px;
  border-right:1px solid #ddd;
  
}
.widecolumns > div {
  border-bottom: 1px solid #ddd;
}
.widecolumns {
  overflow-y: auto;
  max-height: 1000px !important;
}
a#nav-dropdown-dark-example {
  color: #ffffff;
}

.dropdown-menu.show.dropdown-menu-dark {
  background: #ffffff;
  box-shadow: 0 10px 10px rgba(0,0,0,0.5);
  border: 0 none transparent !important;
  margin-top: 10px;
}

a.dropdown-item {
  color: #000000;
}

a.dropdown-item:hover {
  color: #398fda
}

/*login page css*/
.pageheight.login {
  padding-top: 40px;
  min-height: calc(100vh - 122px);
  padding-bottom: 40px;
  display: flex;
  align-items: center;
}

.login .partition {
  margin: 0 auto;
  max-width: 400px;
}
html body .btn-outline-secondary {
  --bs-btn-color: #898989;
  --bs-btn-border-color: #ced4da;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #398fda;
  --bs-btn-hover-border-color: #398fda;
  --bs-btn-focus-shadow-rgb: 108,117,125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #398fda;
  --bs-btn-active-border-color: #398fda;
  --bs-btn-active-shadow: inset 0 3px 5px
  rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
} 

.profileicon .nav-item.show {
  position: relative;
}
.profileicon .dropdown-menu.show.dropdown-menu-dark {
  left: auto;
  right: 0;
}
a#profileicondropdown {
  color: #03316d !important;
}
a#profileicondropdown:after {
  display: none;
}


/* Result Page */
.whitepartition {
  background: #fff;
  padding: 1em 1.5em;
  margin: 0 auto 30px;
  border-radius: 0.375rem;
  box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
}
.helptext {
  line-height: 1.15;
}
.dropdownrow {
  margin-top: 10px;
  margin-bottom: 10px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background: #398fda !important;
    color: #FFf !important;
    border: 1px solid #398fda !important;
    font-family: roboto;
    font-weight: 700;
}

ul.nav.nav-tabs {
  border-color: #398fda !important;
}

.tab-content {
  border: 10px solid #398fda;
  padding: 20px;
  margin-bottom: 20px;
}


.bootstrap-demo .dropdown-trigger {
  border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger > span:after {
  font-size: 16px;
  color: #000;
  
}

.bootstrap-demo .toggle {
  font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    background-color: #03316d;
    color: #fff;
    margin-right: 5px;
    font-size: 15px;
    line-height: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    width: 15px;
}
.toggle.collapsed[style="visibility: hidden;"] {
  display: none !important;
}

.node label {
    white-space: nowrap;
}
.node label {
    white-space: nowrap;
}

div.react-dropdown-tree-select ul.tag-list span.tag , span.facilityCodeState {
  background: rgba(0,0,0,0.05);
  padding: 5px;
  border-radius: 3px;
  display: flex;
  align-items: start;
  font-size: 14px;
  justify-content: space-between;
}
div.react-dropdown-tree-select ul.tag-list span.tag .tag-remove , button.facilityCodeStateClose {
  height: 17px;
  line-height: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #666666;
  color: #fff;
  border: 0 none transparent;
  border-radius: 50%;
  width: 17px;
  font-size: 10px;
  padding: 1px;
  line-height: 2px;
  text-transform: capitalize;
}
.bootstrap-demo .toggle.collapsed::after {
  content: "+";
}

.bootstrap-demo .toggle.expanded::after {
  content: "-";
  position: relative;
  top:-1px;
}

.bootstrap-demo .root {
  padding: 0px;
  margin: 0px;
}

ul.tag-list {
  list-style: none;
  padding: 0;
}



span.node-label {
  font-size: 14px;
  color: #000;
}


input.search {
  width: 100%;
  color: #212529;
  display: block;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  font-family: Roboto,sans-serif;
  font-size: 14px!important;
}
.infinite-scroll-component {
  height: 150px !important;
  overflow: auto !important;
}

.node input[type=radio],.node input[type=checkbox] {
  margin-right: 10px;
  padding: inherit;
}

.navlink{
  background-color: #e7e7e7 !important
}

@media(min-width:768px)
{
.dropdown-content {
    width: calc(200% + 30px);
    z-index: 1021;
    position: relative;
}
}

.info-model-body {
  max-height: calc(100vh - 250px);
  overflow-y: auto; }

  .dropdown-menu[data-bs-popper].dropdown-menu.show.dropdown-menu-dark {
    left: auto;
    right: 0;
}

a#nav-dropdown-dark-example + .dropdown-menu.show.dropdown-menu-dark a.dropdown-item {
  padding: 10px;
  border-top: 1px solid #f1f1f1;
}

a#nav-dropdown-dark-example + .dropdown-menu.show.dropdown-menu-dark a.dropdown-item:first-child {
  border-top: 0 none transparent;
}
.toplinks a#nav-dropdown-dark-example+.dropdown-menu.show.dropdown-menu-dark a.dropdown-item:last-child {
  background: rgba(0,0,0,0.07);
}

a#nav-dropdown-dark-example+.dropdown-menu.show.dropdown-menu-dark {
  padding-bottom: 0;
  padding-top: 0;
}

.customSearchButton {
  position: relative;
  z-index: 100;
  bottom: auto;
  top: 0;
  left: 0;
  right: 0;
  padding: 5px 10px;
  background: white;
  text-align: right;
 
}
.bootstrap-demo .customSearchButton {
  background: #e7e7e7;
}
div#serachtabs-tabpane-analyte .patients-search-results {
  top: 37px;
}
.customParentDiv {
  position: absolute;
  width: auto;
  left: 12px;
  top: 37px;
  display: block;
  right: 12px;
  z-index: 100;
}

.customParentDiv .patients-search-results {
  position: static;
}
.searchResultsTable th {
  white-space: nowrap;

}
.searchResultsTable tr {
  border-color: #dee2e6;
}
div.react-dropdown-tree-select ul.tag-list {
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
}
li.tag-item + li.tag-item {
  margin-bottom: 10px;
}

ul[aria-label="Pagination"] {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}
ul[aria-label="Pagination"]  li a{
position: relative;
  display: block;
  color: #398fda !important;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
}
ul[aria-label="Pagination"]  li a:hover {
  z-index: 2;
  color: #398fda !important;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
ul[aria-label="Pagination"]  li.active a{
  z-index: 3;
  color: #fff !important;
  background-color: #398fda;
  border-color: #398fda;
}
ul[aria-label="Pagination"]  li.disabled a{
  color: #6c757d !important;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}
.resultFacilityTree .dropdown-content, .insuranceTree .dropdown-content, .providerTree .dropdown-content  {
  position: absolute;
  background: #e7e7e7;
  padding-bottom: 15px;
  padding-left: 10px;
  top: 0px;
    left: 5px;
    right: 0px;
    width: auto;
}

.resultFacilityTree .non-selective label{
 
  pointer-events:all;
  opacity:1;

}
.resultInsuranceTree .non-selective label{
 
  pointer-events:all;
  opacity:1;

}
.resultInsuranceTree li{
 padding-left: 0 !important;

}
.resultInsuranceTree .non-selective i{
 display: none;

}
.resultProviderTree .non-selective label{
 
  pointer-events:all;
  opacity:1;

}
.resultProviderTree li{
  padding-left: 0 !important;
 
 }
 .resultProviderTree .non-selective i{
  display: none;
 
 }

/* .resultFacilityTree .tag-list .tag-item:not(:last-child) {
  display: none;
} */

.resultFacilityTree li.tag-item + li.tag-item {
  margin-bottom: 10px !important;
}

.resultAdvancesearch {
  background: #e7e7e7;
  padding: 20px;
  margin-top: 20px;
}



/* .tag-item .search{
  border: none;
} */
/* .react-dropdown-tree-select .dropdown .dropdown-trigger {
  background: white;
  width: 100%;
} */



.profileiconbigger {
  background: #f4fbff;
  outline: 10px solid #07449d;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 90px;
  font-weight: bold;
  letter-spacing: -1px;
  color: #073e8f;
  margin: 20px auto 40px;
  padding-bottom: 10px;
}

.spacingdiv {
  margin: 20px 0 30px;
}

.spacingdiv h5 {
  margin: 0 0 0px;
}

div.react-dropdown-tree-select ul.tag-list {
  margin-top: 45px;
  max-height: 115px;
  overflow-y: auto;
}

li.tag-item:last-child {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
}
.patients-advance-search-results.mt-3.widecolumns.noHover {
  /* overflow: visible; */
  max-width: none;
  max-height: none !important;
  margin-bottom: 30px;
  border: 0 none transparent;
}
.patients-advance-search-results.mt-3.widecolumns.noHover .row {
  border-left: 1px solid #ddd;
}
.cursorPointer {
  cursor: pointer;
}
p.help-text {
  color: #666;
  font-size: 12px;
  line-height: 16px;
}
.highLight.pills {
  border: 1px solid #ffc107;
  box-shadow: 0px 0px 10px #ffc107;
}

.MNDialogBox{
  width: 100%;
}
.MNDialogBox.table td, .table th {
  border-left: 1px solid #dee2e6;
}

.MNDialogBox.table td:first-of-type, .table th:first-of-type {
  border-left:0 none transparent;
}
.MNDialogBox.table th {
  background-color:#eee;
}
.MNDialogBox.table {
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

/* .facilityTree .dropdown, .insuranceTree .dropdown, .providertree .dropdown{
  width: 100%;
} */

/*responsive settings for search results*/
@media (max-width:767px) {
  .searchcontainer button.outline.btn.button.ml-auto.btn.btn-outline {
    position: absolute;
    right: 0;
    top: 0;
}
.searchcontainer {
    position: relative;
    padding: 0;
    flex-wrap: wrap;
}

span.titletext {
    width: 100%;
    padding: 0px 40px 10px 0px;
}

.searchcontainer select.w-25.ml-2.form-select {
    margin-left: 0 !important;
    width: calc(100% - 127px) !important;
}

.whitepartition.searchresultsfilterform >  .row > div {
  margin-bottom: 20px;
}

.whitepartition.searchresultsfilterform >  .row > div input.input-style.form-control {
  margin: 0;
}
.dropdownrow.row > div:first-child {
  margin-bottom: 5px;
}

.dropdownrow.row {
  margin-bottom: 20px;
}
}

.resultsname {
  flex: auto !important;
}
.resultsdate{
  flex: auto !important;
}
.reultInboxCheckBox input.form-check-input {
  position: static;
  margin-left: 5px;
}
/*inner page*/
.endtext {
  text-align: center;
  font-weight: bold;
}
.rightcontrolls {
  display: inline-flex;
  align-items: center;
}
.datecol {min-width: 120px;}

.testdes {
    min-width: 200px;
}
.resultsvalcol {
    min-width: 115px;
}
.mediumCol {
    min-width: 80px;
}
.draftbar  .actionsbar .leftcontrolls {
  margin-right: auto;
}

.draftbar  .actionsbar .centercontrolls {
  margin: auto;
}

.draftbar .actionsbar .rightcontrolls {
  margin-left: auto;
}

.draftbar  .actionsbar {
  width: 100%;
  align-items: center;
}

.draftbar .actionsbar .centercontrolls button.outline.btn {
  min-width: auto;
  margin-left: 10px;
  margin-right: 10px;
}

.draftbar .actionsbar .rightcontrolls .archiveCheckBox.form-check.form-switch.mr-2 {
  margin-right: 10px;
}

.smallheading {
    font-size: 13px;
    text-align: center;
    display: inline;
    text-transform: none;
}
.infoValue {
    text-align: center;
    font-size: 13px;
    font-weight: 700;
    display: inline;
    text-transform: none;
}

.borderedtables {
    background: #398fda;
    border: 0 none transparent;
    color: #fff;
    border-radius: 5px !important;
    overflow: hidden;
    padding: 20px;
}

.smallheading:after {content: ": ";}
/* .borderedtables .row > div {
  width: auto;
  flex: auto;
} */

.MNDialogBoxContainer{
    max-height: 250px;
    overflow: auto;
}
.MNDialogBoxRows{
  padding-left: 0 !important;
}
.suggestedDiagnosisModal{overflow: auto;
  height: 350px;}
  .resultsPage{
    padding-top: 20px;
  }

  .draftbar .actionsbar .centercontrolls button.outline.btn svg {
    position: relative;
    top: -2px;
}
/*new css for sticky [paitients info*/
.topinfo.resultsdetailpage {
  position: fixed;
  right: auto;
  left: 50%;
  max-width: 1160px;
  padding: 0;
  top: 89px;
  background: #398fda;
  border-radius: 0 0 5px 5px;
  border: 0 none transparent;
  box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
  color: #fff;
  transform: translateX(-50%);
  flex-direction: column;
}
.topinfo .infodiv{
  font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-content: space-around;
    align-items: center;
    width: 100%;
}
div#moreinfop {
  width: 100%;
}

div#moreinfop .col {
  flex: unset;
  width: auto;
}


div#moreinfop .row {width: 100%;justify-content: space-between;}
.resultsdetailpage.topinfo:before {
  height: auto;
}

.topinfo .infodiv button.btn.btn-primary {
  padding: 0;
  background: transparent;
  font-size: 12px;
  border: 0 none transparent;
}

.infodiv {
  min-height: 25px;
}

.topinfo.resultsdetailpage > div {
  min-height: 35px;
}
.sticky-top {
  z-index: 90;
}
.errorsCount{
  color: #0d6efd !important;
}

.word-break{
  word-break: break-word;
}
.labelCopies{
  width: 50px;
  margin-right: 10px;
}

tr.DarkHeading th {
  background: #03316d !important;
  color: #fff ;
}
.labelCopiesInput{
  width: 70px !important;
  display: inline !important;
}


#PrintPreviewDialog .modal-dialog.modal-xl {
  max-width: 1300px;
}
#PrintPreviewDialog .modal-dialog.modal-xl .modal-body .container {
  max-width: 100%;
}
#PrintPreviewDialogInOrderManifest .modal-dialog.modal-xl {
  max-width: 1300px;
}
#PrintPreviewDialogInOrderManifest .modal-dialog.modal-xl .modal-body .container {
  max-width: 100%;
}
.react-datepicker-wrapper{
  display: block !important;
}
.overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(235 226 226 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}
.orderTemplateNumber{
  cursor: pointer;
  text-decoration: underline;
  color: #398fda;
}

.swal2-confirm {
  background: #03316d linear-gradient(99deg, #063579 0%, #074EB4 100%) !important;
  color: #fff;
  border: 1px solid #03316d;
  font-family: roboto;
    font-weight: 700;
}
.swal2-cancel{
  border: 1px solid #03316d !important;
  color: #03316d !important;
  background: transparent !important;
  font-weight: 600 !important;
}
.swal2-cancel:focus{
  box-shadow: none !important;
}
.swal2-confirm:focus{
  box-shadow: none !important;
}
.pageheight.resultsPage.withOutbar {
    min-height: calc(100vh - 130px);
}
@media(max-width:1024px){
  .patients-advance-search-results .col-md-1 {
    width: 8.33333333%;
  }
  .patients-advance-search-results .col-md-2 {
    width: 16.66666667%;
  }
  .patients-advance-search-results .col-md-3 {
    width: 25%;
  }
  .patients-advance-search-results .col-md-4 {
    width: 33.33333333%;
  }
  .patients-advance-search-results .col-md-5 {
    width: 41.66666667%;
  }
  .patients-advance-search-results .col-md-6 {
    width: 50%;
  }
  .patients-advance-search-results .col-md-7 {
    width: 58.33333333%;
  }
  .patients-advance-search-results .col-md-8 {
    width: 66.66666667%;
  }
  .patients-advance-search-results .col-md-9 {
    width: 75%;
  }
  .patients-advance-search-results .col-md-10 {
    width: 83.33333333%;
  }
  .patients-advance-search-results .col-md-11 {
    width: 91.66666667%;
  }
  .patients-advance-search-results > .row {
    min-width: 1044px;
  }
  html body .patients-advance-search-results {
    overflow-x: scroll;
  }
  }

  .floatpatientfooter{
    width: calc(100vw - 20px) !important;
    margin-left: calc(-50vw + 50% + 10px) !important;
  }

  @media(max-width:767px){
    .orders-filters {
        flex-direction: column;
    }
    }

  .draftbar > .floatpatientfooterConatiner > span {
      margin-right: 0;
  }

  /* .requisitionPreview {
    max-width: 1300px !important;
}

.requisitionPreview .container {
    max-width: 1300px !important;
} */

svg.pl-l {
  float: left;
  margin: 0 10px;
}

svg.pl-r {
  float: right;
  margin: 0 10px;
}



@media(min-width:1024px){
  .requisitionPreview .modal-dialog.modal-xl{
      max-width: 95vw !important;
  }
  
  .requisitionPreview .container {
      max-width: 100% !important;
  }
  
  .requisitionPreview .modal-body {
      max-height: 100vh !important;
      height: 100vh !important;
  }
  
  .requisitionPreview .modal-content {
      height: calc(100vh ) !important;
  }
  
  .requisitionPreview .col-md-8 iframe {
      height: calc(100vh - 300px);
  }
  }

  .requisitionPreviewIframe{
    height: calc(100vh - 240px) !important;
  }
  img.position-icon {
    right: auto;
}

  
  @media(max-width:767px){
    .mobile-col-row {
      flex-direction: column;
    }
    .resultFacilityTree .dropdown-content, .insuranceTree .dropdown-content, .providerTree .dropdown-content {
      position: relative;
  }
  .dropdown-menu.show.dropdown-menu-dark {
    max-width: calc(100vw - 40px);
    position: fixed;
    top: 50px;
    left: 10px !important;
    right: 10px !important;
}

.dropdown-menu.show.dropdown-menu-dark a.dropdown-item {
    white-space: normal;
}
  }

  @media(max-width:767px){
    ul.pagination .link {
        display: none;
    }
    ul.pagination li.link.active {
        display: list-item;
    }
    ul.pagination li.break {
        display: none;
    }
    }
    .strike{
      text-decoration: line-through;
    }

    .whiteSpaceNoWrap {
      white-space: nowrap;
  }
  .w-90{
    width: 90px;
  }
  .checkboxHeader{
    
  }
  .shrink-table{
    width: max-content !important;
  }

  .loginScreenTree .dropdown-content{
    max-width: 350px;
  }
